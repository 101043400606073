import React from 'react';
import PropTypes from "prop-types";


const Cita = ({cita, eliminarCita}) => {
    return (
        <div className="media mt-3">
            <div className="media-body">
                <h2 className="mt-0">
                    {cita.mascota}
                </h2>
                <div className="card-text"><span>Nombre Dueño: </span> {cita.propietario}</div>

                <div className="card-text"><span>fecha : </span> {cita.fecha}</div>
                <div className="card-text"><span>Hora : </span> {cita.hora}</div>
                <div className="card-text"><span>Síntomas: </span> </div>
                <div className="card-text">{cita.sintomas}</div>

                <button 
                    className="btn btn-danger"
                    onClick={() => eliminarCita(cita.id)}
                    >Borrar &times;
                </button>
            </div>
        </div>
    );
};


Cita.propTypes = {
    cita: PropTypes.object.isRequired,
    eliminarCita: PropTypes.func.isRequired
}


export default Cita;